import axios from 'axios';
export const apiBaseUrl = `/api/`;

const Axios = axios.create({
  baseURL: apiBaseUrl,
  timeout: 14000,
  withCredentials: false // true allows setting/passing cookies
});

export default Axios;

/**
 * A Request interceptor.
 * first callback intercepts successfully formed requests
 * second callback handles errors, so pass through
 */
Axios.interceptors.request.use(
  (request) => {
    const csrfToken = document.cookie.split('csrftoken=')[1].split(';')[0];
    if (csrfToken) request.headers['x-csrftoken'] = csrfToken
    return request;
  },
  (error) => Promise.reject(error)
);

/**
 * A Response interceptor.
 * first callback handles success, so pass through
 * second callback handles errors
 */
// Axios.interceptors.response.use((success) => success, handleResponseError);

// function handleResponseError(error) {
//   if (error?.response?.status === 401) handle401Response(error);
//   if (error?.response?.status === 403) handle403Response(error);
//   return Promise.reject(error);
// }

// function handle401Response(error) {
//   /*
//     For now, just pass the response through.
//     In the future, if we want to handle 401s differently based on originating url, 
//     or whatever, we can handle that here via error.config (which is a axios config object)
//   */
//   if (requestIsInternal(error.config.url) && error.config.url !== 'TOKEN') return handleUnauthorizedOrForbidden();
//   return Promise.reject(error);
// }

// function handle403Response(error) { // added quotes to TOKEN to get abuild going
//   if (requestIsInternal(error.config.url) && error.config.url !== 'TOKEN') return handleUnauthorizedOrForbidden();
//   return Promise.reject(error);
// }

/**
 * @param {String} requestUrl - the URL being requested
 * @returns {Boolean} true if the request is bein made to our own REST API
 * For now, we're simply assuming that if the request URL includes the protocol,
 * it is an external request. This works as long as we continue to use relative paths
 * for our internal axios requests.
 */
// export function requestIsInternal(requestUrl) {
//   return !requestUrl.includes('http');
// }

// function handleUnauthorizedOrForbidden() {
//   clearLocalStorageForLogout();
//   window.location = loginRoute.path;
// }
