import { useEffect, useState, useReducer } from 'react';
import './css/App.css';
import axios from 'axios'
import NewsList from './components/NewsList'
import Stats from './components/Stats'
import Timer from './components/Timer'

import reducer, { initialState } from './components/reducer'
import { getUser, getTimer } from './components/actions'

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [scoresOpen, setScoresOpen] = useState(false)
  const [loginFired, setLoginFired] = useState(false)
  const is_dev = process.env.NODE_ENV === 'development'

  useEffect(() => {
    window.addEventListener('message', e => { 
      if (e.data === 'login fired') {
        setLoginFired(true)
        setTimeout(() => {
          setLoginFired(false)
        }, 500)
      }

      if (e.data === 'scores toggle') {
        setScoresOpen(window.scoresOpen)
      }
    })

    // check if user is logged in, add user to state
    getUser(dispatch)

    // preload placeholder image
    const img = new Image()
    img.src = '/static/img/A_black_image.jpeg'

    // get initial time from timer endpoint
    getTimer(dispatch)

  }, [])

  useEffect(() => {
    if (loginFired) {
      getUser(dispatch)
    }
  }, [loginFired])

  return (
    <div className="App">
      <Timer store={{ state, dispatch }} />   
      {Object.keys(state.user).length !== 0 && <NewsList store={{ state, dispatch }} />}
      <Stats statsOpen={scoresOpen} setStatsOpen={setScoresOpen} store={{ state, dispatch }} />
    </div>
  );
}

export default App;
