import React, { useEffect, useState } from 'react';
import '../css/ProgressImage.css'

import HundredP from './HundredP'


export function ProgressImage({ item, jumble, isLink=false }) {
  const [img, setImg] = useState('/static/img/A_black_image.jpeg')

  useEffect(() => {
    const img = new Image()
    img.src = item.image
    img.onload = () => { 
      setImg(img.src)
    }
  }, [])

  
  return (
    <div className={isLink ? 'ProgressImage is-link' : 'ProgressImage'}>
      {jumble.is_sorted && <HundredP />}
      <img draggable="false" src={img} />
      <div className="jumble-stats">
        <div className="progress">
          <div style={{"width":`${jumble.percent_complete}%`}}></div>
        </div>
        <div className="feedback">
          <div className="percent-complete">{jumble.percent_complete}%</div>
          <div className="move-count">{jumble.move_count} moves</div>
        </div>
      </div>
    </div>
  );
}

export default ProgressImage;
