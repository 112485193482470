import React, { useState, useEffect } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import SortableWord from './SortableWord';

export function WordContainer({
  id, 
  words, 
  overObj, 
  handleEveryMove, 
  setLetterIsDragging, 
  newsItemId, 
  store, 
  update=null
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({
    id: id,
    transition: {
      duration: 250,
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
    },
  });
  
  const style = {
    transform: 'translate3d(0px, 0px, 0px)',
    transition,
  };

  const containerClassName = () => {
    let text = ""
    if (overObj.id === id && overObj.isLeft) {
      text = 'word-container over-left'
    } else if (overObj.id === id && !overObj.isLeft) {
      text = 'word-container over-right'
    }
    else if (isDragging) {
      text = 'word-container dragging'
    }
    else {
      text = 'word-container'
    }
    return text
  }

  return (
    <div 
      className={containerClassName()} 
      ref={setNodeRef} 
      style={style} {...attributes} {...listeners}
    >
      <SortableWord 
        key={id} 
        id={id} 
        words={words}
        handleEveryMove={handleEveryMove}
        setLetterIsDragging={setLetterIsDragging}
        newsItemId={newsItemId}
        store={store}
        update={update}
      />
    </div>
  );
}

export default WordContainer
