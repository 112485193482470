import React, {useEffect} from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

export function SortableLetter({id, letter, index}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: id,
    transition: {
      duration: 250,
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
    },
  });
  
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };
  
  return (
    <div ref={setNodeRef} className={letter === 'I' ? "upper-I" : ""} style={style} {...attributes} {...listeners}>
      {letter}
    </div>
  );
}

export default SortableLetter;
