import React, { useEffect, useState, useRef} from 'react';
import '../css/WordModal.css'

import WordContainer from './WordContainer';

export function WordModal(props) {
  const [modalOpen, setModalOpen] = useState(false)
  const [modalStyles, setModalStyles] = useState({})
  const [helperStyles, setHelperStyles] = useState({})
  const [handlePosition, setHandlePosition] = useState(0)
  const [usingHandle, setUsingHandle] = useState(false)
  const [canScroll, setCanScroll] = useState(false)

  const wordContainerRef = useRef()

  const getCoords = () => {
    const rect = props.e.target.offsetParent.getBoundingClientRect()
    return {
      screenWidth: document.body.clientWidth,
      screenHeight: document.body.clientHeight,
      xScale: rect.width/document.body.clientWidth,
      yScale: rect.height/document.body.clientHeight,
      top: rect.top,
      left: rect.left
    }
  }

  const handleTouchClose = () => {
    setModalOpen(false)
    setModalStyles({ transition: "all 0.2s" })
    setHelperStyles({})
    setTimeout(() => {
      props.setModalData({})
    }, 200)
  }

  const handleLongWordScroll = (pos, sider) => {
    const scrollEl = wordContainerRef.current.firstChild.firstChild
    const realWidth = window.innerWidth - 64
    const fractionScroll = ((realWidth - 2*sider)/2 - pos)/(realWidth - 2*sider)
    const scrollRange = scrollEl.scrollWidth - wordContainerRef.current.scrollWidth
    scrollEl.scrollLeft = (1 - fractionScroll) * scrollRange
  }

  const handleHandleMove = (e) => {
    const sider = 120
    let pos = e.changedTouches[0].clientX > 0 + sider ? 
      e.changedTouches[0].clientX - window.innerWidth/2 : 
      sider - window.innerWidth/2
    pos = e.changedTouches[0].clientX < window.innerWidth - sider ? 
      pos : 
      window.innerWidth/2 - sider
    
    setHandlePosition(pos)
    handleLongWordScroll(pos, sider)
  }

  useEffect(() => {
    if (!usingHandle) {
      // NOTE: This useEffect keeps the logic of the scrolling handle 
      // within this component.  It's a bit akward here.
      // helps the handle follow the scroll position
      const scrollEl = wordContainerRef.current.firstChild.firstChild
      const scrollRange = scrollEl.scrollWidth - wordContainerRef.current.scrollWidth
      const frac = scrollEl.scrollLeft/scrollRange
      const handleRange = ((window.innerWidth) - (2*120))
      setHandlePosition(((handleRange * frac) - handleRange/2))
    }
  }, [wordContainerRef.current?.firstChild.firstChild.scrollLeft])

  useEffect(() => {
    if (props.words[props.id].sorted) {
      setCanScroll(false)
    }
  }, [props.words[props.id].sorted])

  useEffect(() => {
    setModalOpen(!!props.id)
    // Animating modal start postion --> open
    const coords = getCoords()
    setModalStyles({
      transform: `scale(${coords.xScale}, ${coords.yScale})`,
      top: `${coords.top}px`,
      left: `${coords.left}px`
    })
    setTimeout(() => {
      setModalStyles({ transition: "all 0.2s" })
      setHelperStyles({ opacity: 1, visibility: "visible" })
    }, 50)
  },[props.id])

  useEffect(() => {
    setTimeout(() => {
      // Close modal when sorted
      if (props.words[props.id].sorted) {
        handleTouchClose()
      }
    },500)
  }, [props.words[props.id].sorted])

  useEffect(() => {
    // set the initial word container scroll if long word is present
    const el = wordContainerRef.current.firstChild
    if (el.firstChild.scrollWidth > el.clientWidth) {
      setCanScroll(true)
    }    
  }, [wordContainerRef.current])

  return (
    <>
    <div 
      className={modalOpen ? "word-backdrop open" : "word-backdrop"} 
      onTouchStart={handleTouchClose}
    ></div>
    <div 
      style={modalStyles} 
      className={modalOpen ? "WordModal open" : "WordModal"}
    >
      <div 
        className={canScroll ? "transition-helper can-scroll" : "transition-helper"} 
        style={helperStyles} 
        onTouchStart={(e) => e.stopPropagation()}
        ref={wordContainerRef}
      >
        <WordContainer { ...props }/>
        {canScroll && <div className="scroll-handle">
          <div
            style={{
              transform: `translateX(${handlePosition}px)`,
              transition: usingHandle ? 'all 0s' : 'all 0.5s'
            }}
            onTouchMove={handleHandleMove}
            onTouchStart={(e) => setUsingHandle(true)}
            onTouchEnd={(e) => setUsingHandle(false)}
          ></div>
        </div>}
      </div>
    </div>
    </>
  );
}

export default WordModal;