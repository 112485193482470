import React, { useState, useEffect, useRef, useReducer } from 'react';
import {
  DndContext, 
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from '@dnd-kit/sortable';
import {
  restrictToHorizontalAxis,
  restrictToWindowEdges,
} from '@dnd-kit/modifiers';

import { updateJumbleState } from './actions'
import reducer, { initialState } from './reducer'

import SortableLetter from './SortableLetter';

function StyledWord({ children, word }) {
  const getClassNames = () => {
    if (word.ordered && !word.rightEdge && !word.leftEdge) {
      return 'word ordered'
    } else if (word.ordered && word.rightEdge && !word.leftEdge) {
      return 'word ordered right-edge'
    } else if (word.ordered && !word.rightEdge && word.leftEdge) {
      return 'word ordered left-edge'
    } else {
      return 'word'
    }
  }
  return (
    <div className={getClassNames()}>
      {children}
    </div>
  )
}

export function SortableWord({ 
  id, 
  words, 
  handleEveryMove, 
  setLetterIsDragging, 
  newsItemId, 
  store,
  update=null
}) {
  const [letterIds, setLetterIds] = useState([])

  const sortedDiv = useRef()
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const animateSortedSuccess = (wordToMatch) => {
    setTimeout(() => {
      const el = document.createElement('span')
      el.classList.add('animate-sorted-success')
      el.innerHTML = wordToMatch
      sortedDiv.current?.appendChild(el)
      setTimeout(() => {
        el.remove()
      }, 500)
    })
  }

  const updateLocalWordOrders = (letterIds) => {
    const jumble = store.state.jumbles.find(j => j.news_item === newsItemId)
    const wordOrders = { ...jumble.word_orders}
    wordOrders[`word_order_${id}`] = letterIds
    updateJumbleState(store.dispatch, jumble.id, 'word_orders', wordOrders)
  }

  const checkWord = (letterIds) => {
    const isSorted = words[id].w2m === getCurrentWord(letterIds)
    if (isSorted) {
      words[id].sorted = isSorted
      animateSortedSuccess(words[id].w2m)
    }
  }

  const handleDragStart = (event) => {
    setLetterIsDragging(true)
  }

  const handleDragEnd = (event) => {
    const {active, over} = event; 
    if (active.id !== over.id) {
      setLetterIds((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        const updatedLetterIds = arrayMove(items, oldIndex, newIndex)
        checkWord(updatedLetterIds)
        words[id].overlayText = getCurrentWord(updatedLetterIds)
        updateLocalWordOrders(updatedLetterIds)
        handleEveryMove()
        return updatedLetterIds;
      });
    }
    setLetterIsDragging(false)
  }

  const getLetterFromLetterId = (letterId) => {
    const jumbled = words[id].jumbled
    return jumbled.find(l => l[0] === letterId)[1]
  }

  const getCurrentWord = (arr) => {
    return arr.map(li => getLetterFromLetterId(li)).join('')
  }

  useEffect(() => {
    let letterIds = []
    const jumble = store.state.jumbles.find(j => j.news_item === newsItemId)
    if (!jumble.word_orders) {
      letterIds = words[id].jumbled.map(l => l[0])
      updateJumbleState(store.dispatch, jumble.id, 'word_orders', {[`word_order_${id}`]: letterIds })
    } else {
      const thisWordOrderExists = Object.keys(jumble.word_orders).find(key => key.includes(id))
      if (!thisWordOrderExists) { // but some do...
        letterIds = words[id].jumbled.map(l => l[0])
        const wordOrders = { ...jumble.word_orders}
        wordOrders[`word_order_${id}`] = letterIds
        updateJumbleState(store.dispatch, jumble.id, 'word_orders', wordOrders)
      } else {
        letterIds = jumble.word_orders[`word_order_${id}`]
      }
    }
    setLetterIds(letterIds)
    words[id].overlayText = getCurrentWord(letterIds)
    checkWord(letterIds)
  }, [words, update?.forceUpdate])

  return (
    <StyledWord word={words[id]}>
      {words[id].sorted ?
        <div ref={sortedDiv} className="word-sorted">{words[id].w2m}</div>:
        <DndContext 
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
          modifiers={[restrictToHorizontalAxis]}
          autoScroll={true}
        >
          <SortableContext 
            items={letterIds}
            strategy={rectSortingStrategy}
          >
            {letterIds.map((letterId, index) => {
              return (
                <SortableLetter 
                  key={`letter_${index}`}
                  id={letterId}
                  letter={getLetterFromLetterId(letterId)}
                  index={index}
                />
              )
            })}
          </SortableContext>
        </DndContext>
      }
    </StyledWord>
  );
}
export default SortableWord
