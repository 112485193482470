export const getParentByClass = (el, className) => {
  let suspectEl = el
  while (!suspectEl?.classList.contains(className)) {
    suspectEl = suspectEl?.parentElement
    if (suspectEl === 'body' || !suspectEl) {
      return suspectEl
    }
  }
  return suspectEl
}

export const stopBodyScroll = () => {
  var scrollTop = window.scrollY || document.body.scrollTop || document.documentElement.scrollTop;
  document.body.style.top = `-${scrollTop}px`;
  document.body.style.position = 'fixed';
}

export const restartBodyScroll = () => {
  const scrollY = document.body.style.top;
  document.body.style.top = ''
  document.body.style.position = ''
  window.scrollTo(0, parseInt(scrollY || '0') * -1);
}
