import React, { useEffect, useState } from 'react';
import 'swiper/swiper-bundle.css';

import '../css/Stats.css';

import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { EffectCreative } from 'swiper/modules';

import CloseBtn from './CloseBtn';
import Scores from './Scores'
import Archive from './Archive'
import Follows from './Follows'
import AddFollows from './AddFollows'

import { stopBodyScroll, restartBodyScroll } from '../utils'


function UseSwiperEnabler({ statsOpen }) {
  const swiper = useSwiper();
  useEffect(() => {
    if (!statsOpen) {
      swiper.slideTo(1, 1, false)
    }
  }, [statsOpen])
}

function Stats({ statsOpen, setStatsOpen, store }) {
  const [startDrag, setStartDrag] = useState(0)
  const [extraCloseClass, setExtraCloseClass] = useState('')
  // const [prettyColors, setPrettyColors] = useState(false)
  // const [xStart, setXStart] = useState(null)
  // const [red, setRed] = useState(255)
  // const [green, setGreen] = useState(255)
  // const [blue, setBlue] = useState(255)

  const userLoggedIn = store.state.user?.username ? true : false 

  const handleStatsOpen = () => {
    window.scoresOpen = !window.scoresOpen
    setStatsOpen(false)
  }

  useEffect(() => {
    if (statsOpen) {
      stopBodyScroll()
    } else {
      restartBodyScroll()
    }
  }, [statsOpen])

  return ( 
    <div
      className={statsOpen ? 'Stats open' : 'Stats'}
      // style={{backgroundColor: `rgb(${red}, ${green}, ${blue})`}}
    >
      <Swiper 
        className="mySwiper"
        initialSlide={1}
        touchStartPreventDefault={false}
        effect={'creative'}
        allowSlidePrev={userLoggedIn} 
        creativeEffect={{
          prev: {
            shadow: false,
            translate: [0, 0, -800],
          },
          next: {
            translate: ['100%', 0, 0],
          },
        }}
        modules={[EffectCreative]}
        onSliderFirstMove={(swiper, e) => {
          // swiping overscroll right, style Score Card
          if(swiper.activeIndex === 1 && swiper.swipeDirection === 'next') {
            swiper.slides[1].style.borderRadius = "8px"
          }

          // swiping left to go to Archive, style Archive Card
          if(swiper.activeIndex === 1 && swiper.swipeDirection === 'prev') {
            swiper.slides[0].style.borderRadius = "8px"
          }

          // swiping right to go back to Score, style Archive Card
          if(swiper.activeIndex === 0 && swiper.swipeDirection === 'next') {
            swiper.slides[0].style.borderRadius = "8px"
          }

          // setting the close button hidden
          setExtraCloseClass('hidden')
          // const clientX = navigator.maxTouchPoints > 0 ? e.changedTouches[0].clientX : e.clientX
          // setXStart(clientX)
        }}
        onTransitionEnd={(swiper, e) => {
          swiper.slides[0].style.borderRadius = "unset"
          swiper.slides[1].style.borderRadius = "unset"
        }}
        onTouchEnd={() => {
          setExtraCloseClass('')
        }}
        // onSlideNextTransitionStart={(swiper, e) => {
        //   console.log('next')
        // }}
        // onSlidePrevTransitionStart={(swiper, e) => {
        //   console.log('prev')
        // }}
        // onSliderMove={(_, e) => {
        //   if (prettyColors) {
        //     const clientX = navigator.maxTouchPoints > 0 ? e.changedTouches[0].clientX : e.clientX
        //     const width = window.innerWidth < 500 ? (window.innerWidth*3) : (window.innerWidth*1.5)
        //     const fraction = Math.abs(xStart - clientX)/width
        //     // Leaving work for teacher
        //     // magenta == 255, 25, 97 (not used here, saving)
        //     // crimson == 237, 20, 61
        //     // greyPink == 240, 210, 207
        //     // 240 - 237 = 3
        //     // 210 - 20 = 190
        //     // 207 - 61 = 146
        //     setRed(240 - (fraction * 3))
        //     setGreen(210 - (fraction * 190))
        //     setBlue(207 - (fraction * 146))
        //   }
        // }}
        // onReachBeginning={() => {
        //   setPrettyColors(true)
        // }}
        // onFromEdge={() => {
        //   setPrettyColors(false)
        //   setRed(255)
        //   setGreen(255)
        //   setBlue(255)
        // }}
      >
        <SwiperSlide>
          <Archive store={store}/>
        </SwiperSlide>
        <SwiperSlide>
          <Scores statsOpen={statsOpen} store={store} />
        </SwiperSlide>
        <UseSwiperEnabler statsOpen={statsOpen} />
      </Swiper>
      <CloseBtn 
        size={64} 
        handleCloseClick={handleStatsOpen} 
        extraClass={extraCloseClass} 
      />
    </div> 
  )
}

export default Stats;
