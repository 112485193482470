import React, { useEffect, useState, useRef } from 'react';
import '../css/Archive.css';

import { getArchive } from './actions';
import LinkOut from './LinkOut';
import LoadMoreBtn from './LoadMoreBtn';
import Accordion from './Accordion';
import ExpandButton from './ExpandButton';

import { getParentByClass } from '../utils'


export function ArchiveCollapse({ store, startIndex, archiveOpen }) {
  const items = store.state.archive.slice(startIndex, startIndex + 6)

  const [containerHeight, setContainerHeight] = useState()

  const containerDivRef = useRef()

  useEffect(() => {
    if (archiveOpen) {
      setTimeout(() => {
        const rect = containerDivRef.current.getBoundingClientRect()
        setContainerHeight(rect.height)
      }, 500)
    }
  }, [archiveOpen])
  return (
    <Accordion>
      <div ref={containerDivRef} style={{height: `${containerHeight}px`}}>
        {items.map((arc, index) => (
          <div className="archive-item" key={`archive-item_${index}`}>
            <img draggable="false" src={arc.news_item.image} />
            <p>{arc.news_item.title} </p>
            <div className="archive-stats">
              <div className="progress">
                <div style={{"width":`${arc.percent_complete}%`}}></div>
              </div>
              <div className="archive-stats-details">
                <div>
                  <p>Completed: {arc.percent_complete}%</p>
                  <p>Moves: {arc.move_count}</p>
                </div>
                <LinkOut href={arc.news_item.url} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </Accordion>
  )
}

export function Archive({ store }) {
  const [offset, setOffset] = useState(null)
  const [openArchive, setOpenArchive] = useState(-1)
  const [moreLoaded, setMoreLoaded] = useState(true)

  const scrollRef = useRef()

  const getMoreArchived = () => {
    getArchive(store.dispatch, store.state.archiveNextOffset)
    setMoreLoaded(false)
  }

  const longDate = (dateString) => {
    return new Date(dateString.replace(/-/g, '\/')).toDateString();
  }

  const handleArchiveClick = (e, index) => {
    if (index === openArchive) {
      setOpenArchive(-1)
    } else {
      setOpenArchive(index)
      const _scrollToHeader = (behavior=null) => {
        const parentEl = getParentByClass(e.target, 'archive-section-header')
        const rect = parentEl.getBoundingClientRect()
        const pageOffset = scrollRef.current.getBoundingClientRect().top + 16
        const scrollPos = rect.top + scrollRef.current.scrollTop - pageOffset
        const scrollDirections = behavior ? {left: 0, top: scrollPos, behavior: 'smooth'} : {left: 0, top: scrollPos}
        scrollRef.current.scrollTo(scrollDirections)
      }
      _scrollToHeader(true)
      setTimeout(() => {
        _scrollToHeader(false)
      }, 250)
    }
  }

  useEffect(() => {
    if (store.state.user) {
      setOffset(getArchive(store.dispatch))
    }
  }, [store.state.user]);

  useEffect(() => {
    setMoreLoaded(true)
  }, [store.state.archive]);

  return (
    <div className='Archive'>
      <h2 className="stats-h2">ARCHIVE</h2>
      <div ref={scrollRef} className="scroll-container">
        {store.state.archive.map((arc, index) => (
          <div key={`archive_${index}`} >
            {index % 6 === 0 &&
              <>
                <div className={openArchive === index ? 'archive-section-header open' : 'archive-section-header'}>
                  <div className="full-progress">
                    <div style={{"width":`${arc.player.average_percentage_complete}%`}}></div>
                  </div>
                  <h3>{longDate(arc.date)}</h3>
                  <p>Rank: #{arc.player.rank}</p>
                  <p>Score: {Math.round(arc.player.score)}</p>
                  <p>Complete: {Math.round(arc.player.average_percentage_complete)}%</p>
                  <ExpandButton 
                    archiveOpen={openArchive === index} 
                    handleArchiveClick={handleArchiveClick}
                    index={index} 
                  />
                </div>
                <ArchiveCollapse  store={store} startIndex={index} archiveOpen={openArchive === index}  />
              </>
            }
          </div>
        ))}
        {store.state.archiveNextOffset ?  
          <LoadMoreBtn handleClick={getMoreArchived} moreLoaded={moreLoaded} /> :
          <div className="LoadMoreBtnPlaceholder"></div>
        }
      </div>
    </div>
  );
}

export default Archive;
