import { debounce } from 'lodash'

import React, { useEffect, useState, useRef } from 'react';
import '../css/Follows.css'

import CloseBtn from './CloseBtn'
import Search from './Search'

import { getFollows, getPlayers, toggleFollow } from './actions'
import { ThreeDots } from 'react-loader-spinner'


function Follows({ store, statsOpen, refreshFollows, setRefreshFollows, filtersOpen }) {
  const [searchTerm, setSearchTerm] = useState('')
  const [focused, setFocused] = useState(false)
  const [listener, setListener] = useState(false)

  const scrollContainer = useRef()
  const inputElement = useRef()

  const noopText = store.state.user?.username ? 
    `No Player matches "${searchTerm}"` : 
    "You need to be logged in to use this feature"


  const handlePlayerClick = (e, playerId, disabled=true) => {    
    const offsetTop = window.visualViewport.offsetTop
    if (offsetTop > 0 && disabled) {
      return;
    }

    e.target.style.boxShadow = '0 0 2px 2pt rgba(197, 94, 94, 0.2)'

    const followId = getFollowId(playerId)
    const newFollowData = {
        "follower": store.state.user.id,
        "followee": playerId
      }
    const payload = followId ? followId : newFollowData
    toggleFollow(payload).then(res => {
      setRefreshFollows(!refreshFollows)
      setTimeout(() => {
        e.target.style.boxShadow = null
      }, 200)
    })
  }

  const getFollowId = (playerId) => {
    const follow = store.state.follows.find(follow => follow.followee == playerId)
    return !!follow ? follow.id : null
  }

  const preventModalClose = (e) => {
    e.stopPropagation()
  }

  const scrollToTop = () => {
    const scrollHeight = scrollContainer.current.scrollHeight
    scrollContainer.current.scrollTo({left: 0, top: -scrollHeight, behavior: 'smooth'})
  }

  const togglePointerEvents = (type) => {
    document.getElementById('navContent').style.pointerEvents = type
    document.querySelector('.filters').style.pointerEvents = type
    const closeButtons = document.querySelectorAll('.CloseBtn')
    closeButtons.forEach(cb => {
      cb.style.pointerEvents = type
    })
  }

  const alignInput = (event) => {
    const rect = inputElement.current.offsetParent.getBoundingClientRect()
    const elRelativeToDocTop = rect.top + rect.height + document.documentElement.scrollTop
    const scrollPosition = elRelativeToDocTop - event.target.height
    window.scrollTo({left: 0, top: scrollPosition, behavior: 'smooth'})
  }

  const debounceAlignInput = debounce(alignInput, 200)

  const handleFocus = (e) => {
    setFocused(true)
    togglePointerEvents('none')
  };

  const handleBlur = (e) => {
    setFocused(false)
    setTimeout(() => {
      togglePointerEvents(null)
    }, 1000)
  }

  const handleChange = (e) => {
    setSearchTerm(e.target.value)
    if (!e.target.value) {
      setTimeout(() => {
        scrollToTop()
      }, 500)
    }
  }

  useEffect(() => {
    getPlayers(store.dispatch, searchTerm)
    getFollows(store.dispatch)
  }, [store.state.user, statsOpen, refreshFollows, searchTerm]);

  useEffect(() => {
    scrollToTop()
  }, [statsOpen]);

  useEffect(() => {
    // Wow! could this be any more annoying.  This is needed be  
    // able to click player when input is focused. 
    // see `handlePlayerClick` & `togglePointerEvents`

    const handleOffsetPlayerClick = (event) => {
      const offsetTop = window.visualViewport.offsetTop
      if (focused && offsetTop > 0) {

        const el = document.elementFromPoint(event.clientX, event.clientY + offsetTop)
        if (el.classList.contains('player')) {
          const e = { target: el }
          handlePlayerClick(e, el.dataset.id, false)
        }
      }
    }

    if (focused) {
      document.addEventListener('click', handleOffsetPlayerClick, true)
      window.visualViewport.addEventListener('resize', debounceAlignInput)
    }

    return () => {
      window.visualViewport.removeEventListener('resize', debounceAlignInput)
      setTimeout (() => {
        document.removeEventListener('click', handleOffsetPlayerClick, true)
      }, 500)
    };
  }, [focused]);

  return ( 
    <div className={'Follows'}>
      <div
        ref={scrollContainer}
        className="follows-container" 
        onClick={preventModalClose}
      >
        <div className="players-list">
        {store.state.players.length > 0 ? 
          store.state.players.map((player, index) => (
            <div 
              key={`player_${player.id}`}
              data-id={player.id}
              className={!getFollowId(player.id) ? "player" : "player selected" }
              onClick={(e) => handlePlayerClick(e, player.id)}
            >
              {player.username}
            </div>
          )) :
          <div>
            <ThreeDots
              visible={true}
              height="80"
              width="80"
              color="#c55e5e"
              radius={6}
              ariaLabel="puff-loading"
              wrapperStyle={{margin: '16px'}}
              wrapperClass="puff-loader"
            />
            <span style={{color: '#c55e5e'}}>{noopText}</span>
          </div>
        }
        </div>
      </div>
      <div id="SEARCH" className={focused ? "search focused" : "search"} onClick={preventModalClose}>
        <input 
          ref={inputElement} 
          placeholder=" " 
          type="text" 
          onFocus={handleFocus} 
          onBlur={handleBlur} 
          onChange={debounce(handleChange, 200)} 
        />
        <Search />
      </div>
    </div> 
  )
}

export default Follows;
