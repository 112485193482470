import React, { useState } from 'react';
import '../css/Accordion.css';

function Accordion({ children }) {
  return (
    <div className="Accordion">
      {children}
    </div>
  )
}

export default Accordion;