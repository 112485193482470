import React from 'react';
import '../css/CloseBtn.css'

export function CloseBtn({ size, handleCloseClick, extraClass }) {
  
  return (
    <div style={!!size ? {width: `${size}px`, height: `${size}px`} : null} className={`CloseBtn ${extraClass}`} onClick={handleCloseClick}>
      <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" height={size/2} viewBox="0 0 24 24" width={size/2}>
        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
      </svg>
    </div>
  );
}

export default CloseBtn;
