import React from 'react';
import '../css/LoadMoreBtn.css'

import { Oval } from 'react-loader-spinner'

export function LoadMoreBtn({ handleClick, moreLoaded }) {
  const opacity = moreLoaded ? 0 : 1
  return (
    <div className='LoadMoreBtn'>
      <a onClick={handleClick}>
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
          <path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"/>
        </svg>
        <Oval
          visible={true}
          height="64"
          width="64"
          color="#f37d7d"
          secondaryColor="#614444"
          ariaLabel="oval-loading"
          wrapperStyle={{
            position: "absolute",
            transition: "opacity 0.2s",
            opacity: opacity
          }}
          wrapperClass=""
        />
      </a>
    </div>
  );
}

export default LoadMoreBtn;