import axios from '../axios'
import { actions, endpoints } from '../constants'

export const getUser = async (dispatch) => {
  try {
    const { data } = await axios.get(endpoints.PLAYER)
    dispatch({
      type: actions.GET_PLAYER,
      payload: data[0]
    })
  } catch(err) {
    console.log(err)
    dispatch({
      type: actions.GET_PLAYER,
      payload: { id: null }
    }) 
  }
}

export const getNewsItems = async (dispatch) => {
  try {
    const { data } = await axios.get(endpoints.NEWS_ITEMS)
    dispatch({
      type: actions.GET_NEWS_ITEMS,
      payload: data
    }) 
  } catch(err) {
    console.log(err)
  }
}

export const getArchive = async (dispatch, offset=0) => {
  try {
    const { data: { results, next } } = await axios.get(`${endpoints.ARCHIVE}?limit=6&offset=${offset}`)
    dispatch({
      type: actions.GET_ARCHIVE,
      payload: { 
        "results": results,
        "nextOffset": next?.split("offset=")[1]
      }
    }) 
  } catch(err) {
    console.log(err)
  }
}

const prepareJumbleData = (store, newsItem) => {
  // checking here for a jumble too (see: `reconcileJumbles()`)
  let jumble = {}
  const localStoragejumbles = JSON.parse(window.localStorage.getItem('jumbles')) || []
  const lsj = localStoragejumbles.find(j => j.news_item === newsItem.id)

  if (lsj) {
    lsj['player_id'] = store.state.user.id
    lsj['news_item_id'] = newsItem.id
    jumble = lsj
  } else {
    jumble = {
      player_id: store.state.user.id,
      news_item_id: newsItem.id
    }
  }
  
  return jumble
}

export const createJumble = async (store, newsItem) => {
  /* 
    A Method that creates a jumble and saves in the database 
    or local storage if user is not logged in.
  */
  if (store.state.user?.id) {
    const payload = prepareJumbleData(store, newsItem)
    try {
      const { data } = await axios.post(endpoints.JUMBLES, payload)
      if (data) {
        store.dispatch({
          type: actions.ADD_JUMBLE,
          payload: data
        }) 
      }
    } catch(err) {
      console.log(err)
    }
  } else {
    const data = {
      "id": newsItem.jumbleId,
      "player": 0,
      "news_item": newsItem.id,
      "date": newsItem.created,
      "move_count": 0,
      "percent_complete": 0,
      "is_sorted": false,
      "headline_order": null,
      "word_orders": null,
      "is_submitted": false
    }
    store.dispatch({
      type: actions.ADD_JUMBLE,
      payload: data
    })
  }
}

const saveOrCreateJumbles = (store, jumbles) => {
  const unmatchedNewsItems = store.state.newsItems.filter(item => !jumbles.map(j => j.news_item).includes(item.id))
  const matchedJumbles = jumbles.filter(j => store.state.newsItems.map(item => item.id).includes(j.news_item))

  // save matchedJumbles to state (even if empty [] to reset).
  store.dispatch({
    type: actions.GET_JUMBLES,
    payload: matchedJumbles
  })

  if (unmatchedNewsItems.length !== 0) {
    unmatchedNewsItems.forEach((item, index) => {
      item['jumbleId'] = index
      createJumble(store, item)
    })
  }
}


const reconcileJumbles = (store, databaseJumbles) => {
  /* 
    If player did some jumbles without being logged in or offline.
    we make sure to not lose progress for each puzzle
    and take the most complete result for each.
    Note: looping through `databaseJumbles` we know any matching
    localStorage jumbles will have an `id` and can be saved with saveJumble 
  */
  const jumbles = []
  const localStoragejumbles = JSON.parse(window.localStorage.getItem('jumbles')) || []
  for (const index in databaseJumbles) {
    let chosenJumble = {}
    const dj = databaseJumbles[index]
    const lsj = localStoragejumbles.find(j => j.news_item === dj.news_item)
    if (lsj && dj.percent_complete < lsj.percent_complete ) {
      lsj['id'] = dj.id
      // saving progress made while logged out
      saveJumble(store, lsj)
      chosenJumble = lsj
    } else {
      chosenJumble = dj
    }
    jumbles.push(chosenJumble)
  }
  return jumbles
}


export const getJumbles = async (store) => {
  /* 
    A Method that gets jumbles from the database or local storage (if user is not logged in).
    Additionaly, if no jumbles are found in the state or localStorage, they are created
  */
  const userId = store.state.user?.id
  if (userId) {
    try {
      const filters = `?player__id=${userId}&news_item__id__in=${store.state.newsItems.map(item => item.id)}`
      const { data } = await axios.get(`${endpoints.JUMBLES}${filters}`)
      saveOrCreateJumbles(store, reconcileJumbles(store, data))
    } catch(err) {
      console.log(err)
      // create jumbles still 
      // TODO: deal with errors in a user-facing way
      saveOrCreateJumbles(store, [])
    }
  } else {
    const jumbles = JSON.parse(window.localStorage.getItem('jumbles')) || []
    saveOrCreateJumbles(store, jumbles)
  }
}

export const saveJumble = async (store, jumble) => {
  /* 
    A Method that saves a jumble to the database or local storage (if user is not logged in).
  */
  if (store.state.user?.id) {
    try {
      const { data } = await axios.patch(`${endpoints.JUMBLES}${jumble.id}/`, jumble)
    } catch(err) {
      console.log(err)
    }
  } else {
    const jumbles = JSON.parse(window.localStorage.getItem('jumbles')) || []
    const index = jumbles.map(j => j.id).indexOf(jumble.id)
    if (index !== -1) {
      jumbles.splice(index, 1, jumble)
    }
    window.localStorage.setItem('jumbles', JSON.stringify(jumbles))
  }
}

export const updateJumbleState = (dispatch, id, key, value) => {
  dispatch({
    type: actions.UPDATE_JUMBLE_STATE,
    payload: { id, key, value }
  })
}

export const getScores = async (dispatch, filterIsOn) => {
  const query = filterIsOn ? "?filter_players=True" : ""
  try {
    const { data } = await axios.get(`${endpoints.SCORES}${query}`)
    dispatch({
      type: actions.GET_SCORES,
      payload: data
    }) 
  } catch(err) {
    console.log(err)
  }
}

export const getPlayers = async (dispatch, searchTerm) => {
  try {
    const filter = searchTerm ? `&search=${searchTerm}` : ''
    const pagination = '?limit=100'
    const { data } = await axios.get(`${endpoints.PLAYERS}${pagination}${filter}`)
    dispatch({
      type: actions.GET_PLAYERS,
      payload: data.results
    }) 
  } catch(err) {
    console.log(err)
  }
}

export const getFollows = async (dispatch) => {
  try {
    const { data } = await axios.get(endpoints.FOLLOWS)
    dispatch({
      type: actions.GET_FOLLOWS,
      payload: data
    }) 
  } catch(err) {
    console.log(err)
  }
}

export const toggleFollow = async (payload) => {
  if (typeof payload === 'object') {
    try {
      const { data } = await axios.post(`${endpoints.FOLLOWS}`, payload)
      return data
    } catch(err) {
      console.log(err)
    }
  } else {
    try {
      const { data } = await axios.delete(`${endpoints.FOLLOWS}${payload}/`)
      return `${payload} deleted`
    } catch(err) {
      console.log(err)
    }
  }
}

export const getTimer = async (dispatch) => {
  try {
    const { data } = await axios.get(endpoints.TIMER)
    dispatch({
      type: actions.GET_TIMER,
      payload: data[0]
    }) 
  } catch(err) {
    console.log(err)
  }
}
