import React, { useEffect, useState } from 'react';
import '../css/Timer.css';

export function Timer({ store }) {
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)

  const getTime = (lastRun) => {
    const dateTime = new Date(lastRun)
    const countDownFraction = 24 - ((new Date() - dateTime) / (1000 * 60 * 60))
    setHours(Math.floor(countDownFraction))
    const mins = Math.floor((countDownFraction % 1) * 60)
    setMinutes(mins)

    if (mins < -2) {
      // 2 minutes for background tasks to run
      // also, enough time to not trigger endless reloads
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
          // pass
      } else {
        window.localStorage.removeItem('jumbles') 
        window.location.reload()
      }
    }
  };

  useEffect(() => {
    const last_run_at = store.state.timer.last_run_at
    if (last_run_at) {
      const interval = setInterval(() => getTime(last_run_at), 60000);
      getTime(last_run_at)
      return () => clearInterval(interval);
    }
  }, [store.state.timer])

  const finished = store.state.user.jumbles_completed === 6
  const variableText = finished ? 'before next batch' : 'to go'
  const hrsLabel = hours === 0 ? '' : hours === 1 ? 'hr' : 'hrs'
  const minutesLabel = minutes === 1 ? 'min' : 'mins'
  const timerString = `${hours ? hours : ''}${hrsLabel} ${minutes}${minutesLabel} ${variableText}`
  const loadingString = 'Loading...'
  const finalString = minutes < 0 ? loadingString : timerString
  
  return (
    <div className='Timer'>
      <div>
        {finalString}
      </div>
    </div>
  );
}

export default Timer;