// endpoints

export const endpoints = {
  PLAYER: 'player/',
  NEWS_ITEMS: 'news_items/',
  JUMBLES: 'jumbles/',
  SCORES: 'scores/',
  TIMER: 'timer/',
  ARCHIVE: 'archive/',
  PLAYERS: 'players/',
  FOLLOWS: 'follows/',
}

// actions
export const actions = {
  GET_PLAYER: 'GET_PLAYER',
  ADD_JUMBLE: 'ADD_JUMBLE',
  GET_NEWS_ITEMS: 'GET_NEWS_ITEMS',
  GET_JUMBLES: 'GET_JUMBLES',
  UPDATE_JUMBLE_STATE: 'UPDATE_JUMBLE_STATE',
  GET_SCORES: 'GET_SCORES',
  GET_TIMER: 'GET_TIMER',
  GET_ARCHIVE: 'GET_ARCHIVE',
  GET_PLAYERS: 'GET_PLAYERS',
  GET_FOLLOWS: 'GET_FOLLOWS',
}

export const LS_CSRF_TOKEN = 'LS_CSRF_TOKEN'
export const CSRF_HEADER = 'CSRF_HEADER'
