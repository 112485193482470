import React, { useEffect, useState } from 'react';
import '../css/Scores.css'

import Accordion, { Trigger } from './Accordion'
import CloseBtn from './CloseBtn'
import ToggleSwitch from './ToggleSwitch'
import Follows from './Follows'
import AddFollows from './AddFollows'
import FilterToggle from './FilterToggle'


import { getScores } from './actions'
import { ThreeDots } from 'react-loader-spinner'

function Scores({ statsOpen, store }) {
  const storedPlayerFilterStatus = window.localStorage.getItem('playerFilter') === 'true'

  const [poll, setPoll] = useState(null)
  const [filtersOpen, setFiltersOpen] = useState(false)
  const [filterIsOn, setFilterIsOn] = useState(storedPlayerFilterStatus)
  const [refreshFollows, setRefreshFollows] = useState(false)

  const togglePlayerFilter = () => {
    setFilterIsOn(!filterIsOn)
    window.localStorage.setItem('playerFilter', !filterIsOn)
  }

  const openLogIn = () => {
    window.postMessage('open login')
  }

  const openSignUp = () => {
    window.postMessage('open signup')
  }

  useEffect(() => {
    if (statsOpen && store.state.user?.id) {
      clearInterval(poll)
      getScores(store.dispatch, filterIsOn)
      setPoll(setInterval(() => {
        getScores(store.dispatch, filterIsOn)
      }, 10000))
    } else {
      clearInterval(poll)
    }
  }, [store.state.user, statsOpen, filterIsOn, refreshFollows]);

  return ( 
    <div className={'Scores'}>
      <h2 className="stats-h2">
          HIGH SCORES
      </h2>
      <div className={filtersOpen ? "filters open" : "filters"}>
        <div 
          className={filterIsOn ? "filters-toggle on" : "filters-toggle"} 
          onClick={togglePlayerFilter}
        >
          <FilterToggle filterIsOn={filterIsOn} />
          <h3>Players</h3>
        </div>
        <AddFollows
          filtersOpen={filtersOpen}
          setFiltersOpen={setFiltersOpen}
        />
      </div>
      <Accordion>
        <Follows 
          store={store}
          statsOpen={statsOpen}
          refreshFollows={refreshFollows}
          setRefreshFollows={setRefreshFollows}
          filtersOpen={filtersOpen}
        />
      </Accordion>
      <div className="scroll-container">
        {store.state.scores.length > 0 ? 
          store.state.scores.map((player, index) => (
            <div key={`score_${player.id}`} className="score-row">
              <div><span>#{player.rank} </span>{player.username}</div>
              <div>{Math.round(player.score)}</div>
            </div>
          )) :
          <ThreeDots
            visible={true}
            height="80"
            width="80"
            color="#f37d7d"
            radius={6}
            ariaLabel="puff-loading"
            wrapperStyle={{}}
            wrapperClass="puff-loader"
          />
        }
        {!store.state.user?.id &&
          <div className="cta">
            <div>You need to be logged in to use this feature</div>
            <div><a onClick={openLogIn}>Login</a> | <a onClick={openSignUp}>Sign Up</a></div>
          </div>
        }
      </div>
    </div> 
  )
}

export default Scores;
