import React from 'react';
import '../css/HundredP.css';

export function HundredP({ size }) {
  
  return (
    <div style={!!size ? {width: `${size}px`, height: `${size}px`} : null} className='HundredP'>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" enableBackground="new 0 0 64 64">
        <g transform="translate(0 4)" fill="#ec1c24">
          <path d="m7.659 7.68c-.2.097-1.132.491-1.325.491-.542 0-.445-.834-.392-1.131.098-1.423 1.079-1.864 2.259-2.551 1.621-.934 7.167-4.224 8.542-4.224 1.275 0 2.26 2.652 2.26 3.39 0 1.424 0 1.275-7.709 14.387-1.72 2.896-5.746 9.821-5.746 13.11 0 .587.146 1.127.146 1.716 0 .442-.244.687-.688.687-.246 0-.784-.292-1.03-.44-1.864-1.031-3.976-1.376-3.976-3.929 0-2.453 2.112-7.906 3.437-10.114 3.584-5.938 7.561-11.198 8.986-13.06l-.101-.098-4.663 1.767"/>
          <path d="m40.566 8.509c0 8.509-7.711 20.12-18.392 20.874-4.074.31-6.469-3.723-6.469-7.489 0-5.01 3.191-10.504 6.469-14.14 3.903-4.3 10.681-7.756 13.562-7.756 1.817 0 3.99 2.126 3.99 3.103 0 .485-1.065.485-1.379.797 1.998.621 2.219 2.836 2.219 4.609m-8.951 10.369c1.95-2.173 5.718-7.446 5.718-10.37 0-.93-.442-2.613-1.685-2.613-1.15 0-2.306 1.636-2.748 1.636-.353 0-.442-.53-.442-.795-7.977 4.478-11.478 10.769-11.745 15.333-.047.842.133 3.413 1.375 3.413 1.328 0 4.164-1.685 5.184-2.611l4.343-3.993"/>
          <path d="m63.979 7.955c0 7.953-7.206 18.806-17.191 19.51-3.814.291-6.05-3.479-6.05-7 0-4.683 2.982-9.817 6.05-13.213 3.645-4.02 9.985-7.251 12.677-7.251 1.7 0 3.729 1.989 3.729 2.902 0 .453-.994.453-1.285.747 1.865.577 2.07 2.646 2.07 4.306m-8.364 9.692c1.823-2.03 5.344-6.96 5.344-9.692 0-.872-.416-2.444-1.576-2.444-1.076 0-2.153 1.533-2.568 1.533-.334 0-.416-.498-.416-.749-7.455 4.185-10.729 10.07-10.976 14.334-.043.788.123 3.191 1.281 3.191 1.244 0 3.891-1.576 4.851-2.444l4.06-3.729"/>
          <path d="m57.834 35.479c.424 1.186-.198 2.075-1.476 2.527-.772.277-2.298.043-3.049-.132-9.579-2.149-26.326-2.464-35.978.979-1.607.575-3.146 1.346-4.684 2.116.089.08.232.306.285.456.212.589-1.508 1.371-1.954 1.531-1.938.688-4.505.158-5.157-1.666-1.408-3.951 6.637-6.987 9.744-8.093 6.824-2.436 14.751-2.816 21.792-2.542 4.171.124 19.12 1.025 20.477 4.824"/>
          <path d="m61.04 44.04c.502 1.219-.177 2.208-1.601 2.797-.866.353-2.58.23-3.433.107-10.812-1.482-29.632-.434-40.4 4-1.796.739-3.505 1.679-5.217 2.621.102.074.266.304.332.456.252.608-1.661 1.576-2.163 1.782-2.165.89-5.057.539-5.831-1.338-1.669-4.054 7.304-7.93 10.767-9.353 7.612-3.137 16.511-4.193 24.426-4.484 4.687-.211 21.511-.496 23.12 3.41"/>
          </g>
        </svg>
    </div>
  );
}

export default HundredP;

