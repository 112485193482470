import axios from 'axios'
import React, { useState, useEffect, useReducer, useRef } from 'react';
import '../css/NewsItem.css'
import '../css/NewsItemResponsive.css'
import {
  DndContext, 
  DragOverlay,
  closestCenter,
  closestCorners,
  rectIntersection,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
  Modifier,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
  horizontalListSortingStrategy
} from '@dnd-kit/sortable';
import {
  restrictToWindowEdges,
} from '@dnd-kit/modifiers';
import {getEventCoordinates} from '@dnd-kit/utilities';
import Confetti from 'react-confetti'

import { saveJumble, updateJumbleState } from './actions'
import reducer, { initialState } from './reducer'

import LinkOut from './LinkOut';
import NewsImageContainer from './NewsImageContainer';
import WordContainer from './WordContainer';
import WordModal from './WordModal';

const snapLeftEdgeToCursor = ({
  activatorEvent,
  draggingNodeRect,
  transform,
}) => {
  if (draggingNodeRect && activatorEvent) {
    const activatorCoordinates = getEventCoordinates(activatorEvent);
    const isTouch = navigator.maxTouchPoints > 0

    if (!activatorCoordinates) {
      return transform;
    }

    const xTouchOffset = isTouch ? 20 : 0
    const yTouchOffset = isTouch ? 40 : 0
    const offsetX = activatorCoordinates.x - draggingNodeRect.left - xTouchOffset;
    const offsetY = activatorCoordinates.y - draggingNodeRect.top - yTouchOffset;

    return {
      ...transform,
      x: transform.x + offsetX,
      y: transform.y + offsetY - draggingNodeRect.height/2,
    };
  }
}

function NewsItem({ item, store, update }) {
  const nullOverObj = { id: null, isLeft: null }
  const [headlineUuids, setHeadlineUuids] = useState([]);
  const [words, setWords] = useState({});
  const [activeId, setActiveId] = useState(null);
  const [overObj, setOverObj] = useState(nullOverObj);
  const [movingChunk, setMovingChunk] = useState([]);
  const [headlineIsOrderedAndSorted, setHeadlineIsOrderedAndSorted] = useState(false);
  const [letterIsDragging, setLetterIsDragging] = useState(false);
  const [partyTime, setPartyTime] = useState(false);
  const [hideImage, setHideImage] = useState(false);
  const [modalData, setModalData] = useState(false);
  const [initialWordTouch, setInitialWordTouch] = useState(0);
  const [canScroll, setCanScroll] = useState(false);

  const jumble = store.state.jumbles.find(j => j.news_item === item.id)
  const headlineOrder = jumble?.headline_order || []

  const headlineRef = useRef()

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const checkHeadlineSortedAndOrdered = (init=false) => {
    const wordkeys = Object.keys(words)
    const areAllOrderedAndSorted = wordkeys.every(key => words[key].ordered && words[key].sorted)
    const isOnlyOneLeftEdge = wordkeys.filter(key => words[key].leftEdge).length === 1
    const isSorted = areAllOrderedAndSorted && isOnlyOneLeftEdge
    updateJumbleState(store.dispatch, jumble.id, 'is_sorted', isSorted)
    setHeadlineIsOrderedAndSorted(isSorted)
    const orderedWordCount = wordkeys.map(key => words[key].ordered).filter(ordered => !!ordered).length
    const fullyOrderedWordCount = orderedWordCount + (isOnlyOneLeftEdge ? 1 : 0)
    const sortedWordCount = wordkeys.map(key => words[key].sorted).filter(sorted => !!sorted).length
    const percent = Math.round(((fullyOrderedWordCount/(wordkeys.length + 1) + sortedWordCount/wordkeys.length)/2)*100)
    updateJumbleState(store.dispatch, jumble.id, 'percent_complete', percent)
    update.setForceUpdate(Math.random())

    if (!init) {
      setPartyTime(isSorted)
    }
  }

  const handleEveryMove = () => {
    const currentCount = jumble.move_count + 1
    updateJumbleState(store.dispatch, jumble.id, 'move_count', currentCount )
    setTimeout (() => { // next tick, wait for 'move_count' to save
      checkHeadlineSortedAndOrdered()
      setTimeout (() => { // next tick, wait for 'is_sorted' & 'percent_complete' to save
        saveJumble(store, jumble)
      }, 50)
    })
  }

  const checkHeadlineOrder = (updatedHeadlineUuids) => {
    const titleMapUUIDArray = item.title_map

    // clear ordered styles
    Object.keys(words).forEach(key => { 
      words[key].ordered = false
      words[key].leftEdge = false
      words[key].rightEdge = false
    })
    
    updatedHeadlineUuids.forEach((itemUUID, index, jumbled) => {
      const currentUUIDBefore = jumbled[index - 1]
      const currentUUIDAfter = jumbled[index + 1]
      
      const mappedUUIDIndex = titleMapUUIDArray.indexOf(itemUUID)
      const mappedUUIDBefore = titleMapUUIDArray[mappedUUIDIndex - 1]
      const mappedUUIDAfter = titleMapUUIDArray[mappedUUIDIndex + 1]
    
      const isBefore = mappedUUIDBefore === currentUUIDBefore && !!currentUUIDBefore
      const isAfter = mappedUUIDAfter === currentUUIDAfter && !!currentUUIDAfter 
      
      if (isBefore && mappedUUIDBefore) {
        words[itemUUID].ordered = true
        words[mappedUUIDBefore].ordered = true
        if (!isAfter) {
          words[itemUUID].rightEdge = true
        }
      }

      if (isAfter && mappedUUIDAfter) {
        words[itemUUID].ordered = true
        words[currentUUIDAfter].ordered = true
        if (!isBefore) {
          words[itemUUID].leftEdge = true
        }
      }
    })
  }

  const getChunk = (activeUuid, uuids) => {
    let chunk = []
    let leftSearchUuid = activeUuid
    let rightSearchUuid = (
        words[activeUuid]?.ordered && !words[activeUuid]?.rightEdge  ? 
        uuids[uuids.indexOf(activeUuid) + 1] : 
        null // <-- so the right search loop won't run
      )
    while (words[leftSearchUuid]?.ordered) {
      chunk.unshift(leftSearchUuid)
      if (words[leftSearchUuid]?.leftEdge) {
        break;
      }
      leftSearchUuid = uuids[uuids.indexOf(leftSearchUuid) - 1]
    }
    while (words[rightSearchUuid]?.ordered) {
      chunk.push(rightSearchUuid)
      if (words[rightSearchUuid]?.rightEdge) {
        break;
      }
      rightSearchUuid = uuids[uuids.indexOf(rightSearchUuid) + 1]
    } 
    return chunk
  }

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
    setMovingChunk(getChunk(event.active.id, headlineUuids))
  }

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const isNotChunked = !movingChunk.length
      const spliceNumber = isNotChunked ? 1 : movingChunk.length
      setHeadlineUuids((uuids) => {
        const oldIndex = isNotChunked ? uuids.indexOf(active.id) : uuids.indexOf(movingChunk[0])
        uuids.splice(oldIndex, spliceNumber)
        const adjustment = overObj.isLeft ? 0 : 1
        const newIndex = uuids.indexOf(over.id) + adjustment
        const loopChunks = isNotChunked ? [active.id] : movingChunk
        uuids.splice(newIndex, 0, ...loopChunks)
        checkHeadlineOrder(uuids)
        return uuids
      });
      handleEveryMove()
      updateJumbleState(store.dispatch, jumble.id, 'headline_order', headlineUuids )
    }
    setMovingChunk([])
    setActiveId(null)
    setOverObj(nullOverObj)
  }

  const handleDragMove = (event) => {
    const overDiv = event.over
    if (overDiv) {
      const middlePoint = overDiv.rect.left + overDiv.rect.width/2
      const pointerX = event.active.rect.current.translated.left
      setOverObj({ id: overDiv.id, isLeft: pointerX < middlePoint })
    }
  }

  useEffect(() => {
    checkHeadlineOrder(headlineUuids)
    checkHeadlineSortedAndOrdered(true)
  }, [headlineUuids])


  const initalizeItem = () => {
    // init
    const dict = {}
    for (let index in item.jumbled_title) {
      const obj = item.jumbled_title[index]
      const key = Object.keys(obj)[0]
      const w2m = obj[key]
      dict[key] = { 
        jumbled: obj[key].jumbled, 
        w2m: obj[key].w2m,
        sorted: false,
        ordered: false,
        rightEdge: false,
        leftEdge: false
      }
    }
    setWords(dict)

    let uuids = ''
    if (jumble?.headline_order?.length > 0) {
      uuids = jumble.headline_order
    } else {
      uuids = item.jumbled_title.map(t => Object.keys(t)[0])
      updateJumbleState(store.dispatch, jumble.id, 'headline_order', uuids )
    }
    setHeadlineUuids(uuids)
  }

  const handleContainerScroll = (e) => {
    if (e.target.scrollTop > 50) {
      setHideImage(true)
    } else {
      setHideImage(false)
    }
  }

  const handleWordTouchStart = (e) => {
    setInitialWordTouch(e.changedTouches[0].clientX)
  }

  const handleWordTouchEnd = (e, props) => {
    const touchSpread = Math.abs(initialWordTouch - e.changedTouches[0].clientX)
    if(touchSpread < 2) {
      setModalData({ ...props, e, setModalData})
    }
  }

  const determineCanScroll = () => {
    // giving text 1 sec to render text to give height to .headline
    setTimeout(() => {
      // matchHeight = (NewItem margin-top) + (headline div height) + (headline div margin-bottom)
      // see --> NewsItem.css @media only screen and (max-width: 600px)
      const matchHeight = window.innerWidth * 0.56 + headlineRef.current.getBoundingClientRect().height + 160
      if (matchHeight > window.innerHeight) {
        setCanScroll(true)
      }
    }, 1000)
  }

  useEffect(() => {
    initalizeItem()
    determineCanScroll()
  }, [store.state.jumbles])
  
  return (
    <div 
      className={hideImage ? "NewsItem hide-image" : "NewsItem"} 
      onScroll={handleContainerScroll}
    >
      <NewsImageContainer item={item} jumble={jumble} forceUpdate={update.forceUpdate} />
      {partyTime && 
        <Confetti
          numberOfPieces={340}
          recycle={false}
          tweenDuration={6000}
          initialVelocityX={8}
          initialVelocityY={12}
          friction={.99000}
          confettiSource={{x: window.innerWidth/2, y:window.innerHeight/2}}
          onConfettiComplete={() => setPartyTime(false)}
        />
      }
      <div 
        className={canScroll ? "headline can-scroll" : "headline"}
        ref={headlineRef}
      >
        <DndContext 
          sensors={sensors}
          collisionDetection={closestCenter} //closestCorners, rectIntersection, closestCenter
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
          modifiers={[
            snapLeftEdgeToCursor
            ]}
          onDragMove={handleDragMove}
        >
          <SortableContext 
            items={headlineUuids}
            strategy={false ? horizontalListSortingStrategy : rectSortingStrategy}
            disabled={letterIsDragging || headlineIsOrderedAndSorted}
          >
            {headlineUuids.map((id, index) => {
              const props = {
                id, 
                words, 
                overObj, 
                handleEveryMove, 
                setLetterIsDragging, 
                newsItemId: item.id, 
                store
              }
              return !words[id].sorted ? (
                <div 
                  key={id}
                  onTouchStart={handleWordTouchStart}
                  onTouchEnd={(e) => handleWordTouchEnd(e, props)}
                >
                  <WordContainer { ...props } update={update} />
                </div>
              ) : <WordContainer key={id} { ...props }/>
            })}
          </SortableContext> 
          {headlineIsOrderedAndSorted && <LinkOut href={item.url} />}
          <DragOverlay>
            {activeId ? (
              <div className="drag-overlay">
                <div className='word-container dragging'>{words[activeId]?.overlayText}</div>
                {!!movingChunk?.length && <div className="chunk-count">{movingChunk?.length}</div>}
              </div> 
            ): null}
          </DragOverlay>
        </DndContext>
      </div>
      {Object.keys(modalData).length > 0 &&
        <WordModal { ...modalData } />
      }
    </div>
  );
}

export default NewsItem