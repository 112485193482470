import React, { useState } from 'react';
import '../css/NewsImageContainer.css'

import Confetti from 'react-confetti'

import NewsItem from './NewsItem'
import ProgressImage from './ProgressImage'

function NewsImageContainer({ item, jumble }) {
  const [cinemaViewOpen, setCinemaView] = useState(false)

  const confettiWidth = window.innerWidth < 600 ? 
    window.innerWidth + window.innerWidth/8 : 
    window.innerWidth

  return (
    <div 
      className={cinemaViewOpen ? 'NewsImageContainer cinema-view' : 'NewsImageContainer'} 
      onClick={() => setCinemaView(!cinemaViewOpen)}
    >
      <ProgressImage item={item} jumble={jumble} />
      <div className='cinema-backdrop'
        onClick={() => setCinemaView(!cinemaViewOpen)}
      >
        {jumble.is_sorted && cinemaViewOpen &&
          <Confetti
            width={confettiWidth}
            height={window.innerHeight}
            numberOfPieces={340}
            initialVelocityX={8}
            initialVelocityY={-8}
          />
        }
      </div>
    </div>
  );
}

export default NewsImageContainer;
