import { actions } from '../constants'

export const initialState = {
  user: {},
  newsItems: [],
  jumbles: [],
  scores: [],
  archive: [],
  players: [],
  follows: [],
  archiveNextOffset: 0,
  timer: { last_run_at: '' }
}

export default function reducer(state, action) {
  switch (action.type) {
  case actions.GET_PLAYER: {
      return {
        ...state,
        user: action.payload
      };
    }
    case actions.GET_NEWS_ITEMS: {
      return {
        ...state,
        newsItems: action.payload
      };
    }
    case actions.GET_ARCHIVE: {
      // getting filteredResults to avoid duplicates
      const loadedIds = state.archive.map(result => result.id)
      const filteredResults = action.payload.results.filter(result => !loadedIds.includes(result.id))

      return {
        ...state,
        archive: state.archive.concat(filteredResults),
        archiveNextOffset: action.payload.nextOffset
      };
    }
    case actions.GET_JUMBLES: {
      window.localStorage.setItem('jumbles', JSON.stringify(action.payload))
      return {
        ...state,
        jumbles: action.payload
      };
    }
    case actions.ADD_JUMBLE: {
      const updatedJumbles = [ ...state.jumbles, action.payload]
      if (updatedJumbles.length === state.newsItems.length) {
        window.localStorage.setItem('jumbles', JSON.stringify(updatedJumbles))
      }
      return {
        ...state,
        jumbles: updatedJumbles
      };
    }
    case actions.UPDATE_JUMBLE_STATE: {
      const pl = action.payload
      const index = state.jumbles.map(j => j.id).indexOf(pl.id)
      state.jumbles[index][pl.key] = pl.value
      // state.jumbles.splice(index, 1, action.payload)
      return state
    }
    case actions.GET_SCORES: {
      return {
        ...state,
        scores: action.payload
      };
    }
    case actions.GET_PLAYERS: {
      return {
        ...state,
        players: action.payload
      };
    }
    case actions.GET_FOLLOWS: {
      return {
        ...state,
        follows: action.payload
      };
    }
    case actions.GET_TIMER: {
      return {
        ...state,
        timer: action.payload
      };
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}
