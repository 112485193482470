import React, { useEffect, useState, useReducer } from 'react';
import '../css/NewsList.css'

import { getNewsItems, getJumbles, createJumble, updateJumble } from './actions'
import reducer, { initialState } from './reducer'
import NewsImageLink from './NewsImageLink'

import { ThreeDots } from 'react-loader-spinner'

function NewsList({ store }) {
  // `forceUpdate` helps to re-render the components it is passed to 
  // In this case, it helps to show progress bar
  const [forceUpdate, setForceUpdate] = useState(0);
  const update = {forceUpdate, setForceUpdate}
  const isTouch = navigator.maxTouchPoints > 0

  useEffect(() => {
    const newsItemsExist = store.state.newsItems?.length > 0
    if (newsItemsExist) {
      getJumbles(store)
    }
  }, [store.state.newsItems]);

  useEffect(() => {
    getNewsItems(store.dispatch)
  }, [store.state.user]);

  return store.state.jumbles.length === store.state.newsItems.length ? (
    <div className={isTouch ? "NewsListContainer touch" : "NewsListContainer"}>
      <div className="NewsList">
        {store.state.newsItems?.map((item) => (
          <NewsImageLink key={`item_${item.id}`} item={item} store={store} update={update} />
        ))}
      </div>
    </div>
  ) : 
  <div className="news-list-loading">
    <ThreeDots
      visible={true}
      height="80"
      width="80"
      color="#f37d7d"
      radius={6}
      ariaLabel="puff-loading"
      wrapperStyle={{}}
      wrapperClass="puff-loader"
    />
</div>
}

export default NewsList;
