import React, { useEffect, useState } from 'react';

import '../css/NewsImageLink.css'

import NewsItem from './NewsItem'
import CloseBtn from './CloseBtn'
import ProgressImage from './ProgressImage'
import { stopBodyScroll, restartBodyScroll } from '../utils'


function ImageLink({handleMouseDown, handleLinkClick, dataId, isTouch, children }) {
  return isTouch ? (
    <a 
      onTouchStart={handleMouseDown} 
      onTouchEnd={handleLinkClick}
      data-id={dataId}
    >
      {children}
    </a>
  ) : (
    <a
      onMouseDown={handleMouseDown}
      onMouseUp={handleLinkClick} 
      data-id={dataId}
    >
      {children}
    </a>
  )
}

function NewsImageLink({ item, store, update }) {
  const [newsItemId, setNewsItemId] = useState(null);
  const [newsItemOpen, setNewsItemOpen] = useState(false);
  const [newsItemOpened, setNewsItemOpened] = useState(false);
  const [itemPosStart, setItemPosStart] = useState({})
  const [itemScaleStart, setItemScaleStart] = useState({})
  const [preventClick, setPreventClick] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [clickTimeOut, setClickTimeOut] = useState(null);
  const jumble = store.state.jumbles.find(j => j.news_item === item.id)

  const isTouch = navigator.maxTouchPoints > 0

  const animateFastClick = (e) => {
    setTimeout(() => {
      const el = document.createElement('div')      
      el.classList.add('click','animate-sorted-success')
      const rect = e.target.getBoundingClientRect();
      const x = isTouch ? e.changedTouches[0].clientX : e.clientX
      const y = isTouch ? e.changedTouches[0].clientY : e.clientY
      el.style.left = `${x - rect.left - 20}px`
      el.style.top = `${y - rect.top - 20}px`
      e.target.parentElement.appendChild(el)
      setTimeout(() => {
        el.remove()
      }, 500)
    })
  }

  const handleMouseDown = (e) => {
    setPreventClick(false)
    setClickTimeOut(setTimeout(() => {
      setPreventClick(true)
    }, 500))
    const scrollTop = window.scrollY || document.body.scrollTop || document.documentElement.scrollTop
    setScrollPosition(scrollTop)
  }

  const handleLinkClick = (e) => {
    /* prevent click while trying to scroll on touch screen */
    const newScrollPosition = window.scrollY || document.body.scrollTop || document.documentElement.scrollTop
    if (scrollPosition !==  newScrollPosition) return;

    /* prevent click when trying to long press */
    if (preventClick) return;

    animateFastClick(e)
    const doc = document.documentElement;
    const top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
    const y = e.target.parentElement.offsetTop - top
    const x = e.target.parentElement.offsetLeft 
    const startingScale = { transform: `scale(${e.target.offsetWidth/document.body.offsetWidth})` } 
    setNewsItemOpen(true)
    const startPos = { transform: `translate(${x + 'px'}, ${y + 'px'})`}
    setItemPosStart(startPos)
    setItemScaleStart(startingScale)
    setTimeout(() => {
      setItemPosStart({})
      setItemScaleStart({})
      setNewsItemOpened(true) 

      setTimeout(() => {
      stopBodyScroll()
      }, 120)
    }, 120)
    
    
  }

  const handleCloseClick = (e) => {
    restartBodyScroll()

    setTimeout(() => {
      setItemPosStart({ opacity: 0 })
      setItemScaleStart({ transform: 'translate(0px, 10vh})', transition: 'all 0.1s' })
      setTimeout(() => {
        setNewsItemOpened(false) 
        setNewsItemOpen(false)
        setItemPosStart({})
        setItemScaleStart({})      
      }, 500)
    }, 200)
    
  }

  return (
    <div className={newsItemOpened ? 'NewsImageLink open' : 'NewsImageLink'}>
      <ImageLink 
        handleMouseDown={handleMouseDown}
        handleLinkClick={handleLinkClick}
        isTouch={isTouch}
        dataId={item.id}
      >
        <ProgressImage item={item} jumble={jumble} isLink={true} />      
      </ImageLink>
      <div 
        className='NewsItemContainer'
        style={itemPosStart}
      >
        <div 
          className="scale-div"
          style={itemScaleStart}
        >
          <CloseBtn size={64} handleCloseClick={handleCloseClick} />
          <NewsItem item={item} itemSizePos={null} store={store} update={update} />
        </div>
      </div>
    </div>
  );
}

export default NewsImageLink;